<template class="modalContent">
  <modal-content
    ref="gap-block-edit-modal"
    :title="item.entity.block_label"
    @hidden="cancelChanges"
    :showFooter="false"
    width="500"
  >
    <template #content>
      <slot/>
      <BaseButton
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
        @click="saveBlock()"
        :disabled="(toAdd && !isMulti) || disabled"
      >{{ (toAdd && isPeriodProvided) ? 'Add' : 'Save' }}</BaseButton>
    </template>
  </modal-content>
</template>

<script>
// TODO render block here instead of slot usage passing all necessary props

import modalContent from "@shared/modal-content";
import BaseButton from '@shared/components/buttons/BaseButton.vue';

export default {
  props: {
    toAdd: {
      type: Boolean,
    },
    isPeriodProvided: {
      type: Boolean,
    },
    item: {
      type: Object,
      required: true,
    },
    isMulti: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    modalContent,
    BaseButton,
  },
  mounted() {
    this.showModal();
  },
  methods: {
    hideModal() {
      this.$emit('close')
      this.$refs["gap-block-edit-modal"].hideModal();
    },
    showModal() {
      this.$refs["gap-block-edit-modal"].showModal();
    },
    saveBlock() {
      if (this.disabled) return;
      console.log('saveBlock'); // TODO remove
      this.item.validations.$touch();
      if (this.toAdd && this.isPeriodProvided) {
        // TODO optimize conditions
        if (!this.item.validations.$invalid) {
          this.$emit('add');
          this.hideModal();
        }
        return
      }
      if (this.toAdd && !this.isPeriodProvided) {
        this.$emit('add');
        this.hideModal();
      }
      if (!this.item.gap.invalid_gap && !this.toAdd) {
        if (this.item.validations.$invalid) return;
        else this.$emit('save');
      }
      this.hideModal();
    },
    cancelChanges() {
      console.log('cancelChanges'); // TODO remove
      this.$emit('cancel');
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>
.inputBox {
  border: 1px solid lightgray;
  padding: 13px;
  border-radius: 5px;
  margin-top: 14px;
}


::v-deep {

    .vm--modal {
    border-radius: 10px !important;
  }

}
 
</style>